import React, {useContext,useEffect,useRef,useCallback,useMemo,useState} from "react";
import "./Oeuvres.scss";
import { ModelContext } from "providers/ModelProvider";
import Utils from 'utils/Utils';
import Detail from 'components/Oeuvres/Detail';
import Conf from 'Conf';
import { useNavigate } from "react-router-dom";

const parPage=10;
let helperDone=false;

function Oeuvres() {
  const { modele,appState,setAppState,file } = useContext(ModelContext);
  const {showHelper,lang,landing,active,dTop,dBottom,focus,page,screenSize} = appState;
  const navigate=useNavigate();
  const nbPages=Math.ceil(modele.Oeuvres.length/parPage);
  const oeuvres=useMemo(()=>modele.Oeuvres.filter((o,i)=>i>=(page-1)*parPage && i<page*parPage),[modele,page]);
  const interval=useRef(null);
  const focusIdx=useRef(focus);
  const activeCurrent=useRef(null);
  const [ showModal,setShowModal ] = useState(false);
  const setFocus=useCallback((v)=>{
    setAppState(state=>{return{...state,focus:v}});
  },[setAppState]);
  const setPage=useCallback((v)=>{
    setAppState(state=>{return{...state,page:v}});
  },[setAppState]);
  const setLanding=useCallback((v)=>{
    setAppState(state=>{return{...state,landing:v}});
  },[setAppState]);
  const setActive=useCallback((v)=>{
    if (v) navigate('/les-oeuvres');
    setAppState(state=>{return{...state,active:v}});
  },[setAppState,navigate]);
  const setDTop=useCallback((v)=>{
    setAppState(state=>{return{...state,dTop:v}});
  },[setAppState]);
  const setDBottom=useCallback((v)=>{
    setAppState(state=>{return{...state,dBottom:v}});
  },[setAppState]);
  const setShowHelper=useCallback((v)=>{
    setAppState(state=>{return{...state,showHelper:v}});
  },[setAppState]);
  useEffect(()=>{
    if (active) {
      setLanding(false);
      if (activeCurrent.current!==active) {
        setShowModal(false);
        if(!helperDone) {
          helperDone=true;
          setTimeout(()=>{
            setShowHelper(true);
            setTimeout(()=>setShowHelper(false),2000);
          },3000);
        }
      }
      activeCurrent.current=active;
      const idx=oeuvres.findIndex((o)=>o._id===active);
      if (idx!==-1) {
        let h=46;
        if (['xs','sm','md'].indexOf(screenSize)!==-1) h=30;
        let dhb=30;
        if (['lg'].indexOf(screenSize)!==-1) dhb=0;
        if (['md'].indexOf(screenSize)!==-1) dhb=0;
        if (['sm'].indexOf(screenSize)!==-1) dhb=0;
        if (['xs'].indexOf(screenSize)!==-1) dhb=0;
        let dht=0;
        if (['lg'].indexOf(screenSize)!==-1) dht=10;
        if (['md'].indexOf(screenSize)!==-1) dht=30;
        if (['sm'].indexOf(screenSize)!==-1) dht=0;
        if (['xs'].indexOf(screenSize)!==-1) dht=0;
        const couleur=oeuvres[idx].Couleur;
        setAppState(state=>{return{...state,couleur}});
        const root = document.documentElement;
        root.style.setProperty('--background-color', couleur || "#FFF");
        setDTop((oeuvres.length-idx-1)*h+dht);
        setDBottom(idx*h+dhb);
      } else {
        setDTop(0);
        setDBottom(0);
      }
    } else {
      setDTop(0);
      setDBottom(0);
    }
  },[active,setDTop,setDBottom,oeuvres,setLanding,setAppState,page,screenSize,setShowHelper]);
  const startInterval=useCallback(()=>{
    interval.current=setInterval(()=>{
      focusIdx.current=(focusIdx.current+1)%oeuvres.length;
      setFocus(oeuvres[focusIdx.current]);
    },5000)
  },[oeuvres,setFocus]);
  useEffect(()=>{
    if (oeuvres && oeuvres.length>0) setFocus(oeuvres[0]);
    startInterval();
    return ()=>{
      clearInterval(interval.current);
    }
  },[oeuvres,startInterval,setFocus]);
  useEffect(()=>{
    if (focus) focusIdx.current=oeuvres.findIndex((o)=>o===focus);
  },[focus,oeuvres]);
  const l=appState.lang;
  return <div className='oeuvres' onClick={()=>setActive(null)}>
    {landing && oeuvres.map((oeuvre)=>oeuvre.Image_principale ?
      <div key={oeuvre._id} className="background" style={{opacity:focus===oeuvre ? 1 : 0,backgroundImage: `url(${file(Conf.assetsUrl+oeuvre.Image_principale.path)})`}}></div>
      : null
    )}
    <div className='oeuvres-inner'>
      <div className='deltas' style={{height:dTop}}></div>
      {oeuvres.map((oeuvre)=><div key={oeuvre._id} className={'oeuvre'+(active===oeuvre._id?' active':'')+(landing && focus===oeuvre?' focus':'')+(landing?' landing':'')}>
        <div className="titre"
        onClick={(e)=>{e.stopPropagation();setActive(active===oeuvre._id ? null : oeuvre._id);}}
        >
          {Utils.trad(oeuvre,'Titre',l)}
        </div>
        <Detail oeuvre={oeuvre} active={active===oeuvre._id} showModal={showModal} onCloseModal={()=>setShowModal(false)}/>
        {['xs','sm'].indexOf(screenSize)!==-1 && <div className="i" onClick={(e)=>{e.stopPropagation();setShowModal(true);}}/>}
      </div>)}
      {nbPages>1 && !landing && <div className="pagination">
        <div className={"prev"+(page>1 ?' active':'')}>
          <span onClick={()=>setPage(Math.max(1,page-1))}></span>
        </div>
        <div className="center">
          1 ... {nbPages}
        </div>
        <div className={"next"+(page<nbPages ?' active':'')}>
          <span onClick={()=>setPage(Math.min(nbPages,page+1))}></span>
        </div>
      </div>}
      <div className='deltas' style={{height:dBottom+16}}></div>
    </div>
    <div className={"zoom-helper"+(active && showHelper && ['xs','sm'].indexOf(appState.screenSize)!==-1  ? ' show' : '')} onClick={(e)=>{e.stopPropagation();setShowHelper(false);}}>
      <div className="zoom-helper-inner">{Utils.trad(modele.Traductions,'Zoom',lang)}</div>
    </div>
  </div>;
}

export default Oeuvres;
