import React, { useContext,useEffect } from "react";
import './App.scss';
import Topbar from 'components/Topbar';
import Presentation from 'components/Presentation';
import Oeuvres from 'components/Oeuvres';
import Biographie from 'components/Biographie';
import Reflexions from 'components/Reflexions';
import Residences from 'components/Residences';
import Message from 'components/Message';
import Loader from 'components/Loader';
import { ModelContext } from "providers/ModelProvider";
import {
  Routes,
  Route,
} from "react-router-dom";

function App() {
  const { modele,appState,sendForm,setAppState } = useContext(ModelContext);
  useEffect(()=>{
    if (modele.preloaded && modele.Oeuvres && modele.Oeuvres.length>0) {
      setAppState(state=>{return{...state,couleur:modele.Oeuvres[0].Couleur}});
    }
  },[modele.preloaded,modele.Oeuvres,setAppState]);

  return (
    <div className="App">
        { modele.preloaded ? <>
          <Routes>
            <Route path="/presentation" element={<Presentation />} />
            <Route path="/les-oeuvres" element={<Oeuvres />} />
            <Route path="/biographie" element={<Biographie />} />
            <Route path="/reflexions" element={<Reflexions />} />
            <Route path="/residences" element={<Residences />} />
            <Route path="/contacts" element={<Message appState={appState} model={modele} sendForm={sendForm}/>} />
            <Route path="*" element={<Oeuvres />} />
          </Routes>
          <Topbar/>
          </>
          :
          <Loader />
        }
    </div>
  );
}

export default App;
